import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IgnoreNullHttpParams} from '../shared/Ignore-null-http-params';
import {Page, QrCodeDto, ReportCondition} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: "root"})
export class QrCodeRest {
  http = inject(HttpClient);

  search(param: IgnoreNullHttpParams) {
    return this.http.get<Page<QrCodeDto>>(`${environment.serverUrl}/api/qrcode`, {params: param.toHttpParam()})
  }

  generateQrCode(id: number) {
    return this.http.get<{image:string, url: string}>(`${environment.serverUrl}/api/qrcode/generate-qrcode/${id}`)
  }

  save(qrcodeDto: QrCodeDto) {
    return this.http.post<QrCodeDto>(`${environment.serverUrl}/api/qrcode`, qrcodeDto)
  }
  exportReport(condition: ReportCondition, item: QrCodeDto) {
    condition.fileType = 'pdf';
    condition.reportPath = 'qrcode.jasper'
    let param = IgnoreNullHttpParams.fromObject({...condition})
    return this.http.get(`${environment.serverUrl}/api/report/export` ,{params: param.toHttpParam(), responseType: 'blob'})
  }
  savePreQrCode(qrcode: QrCodeDto) {
    return this.http.post<{image:string, url: string}>(`${environment.serverUrl}/api/qrcode/save/pre-qrcode`, qrcode)
  }
  searchAdmin(param: IgnoreNullHttpParams) {
    return this.http.get<Page<QrCodeDto>>(`${environment.serverUrl}/api/qrcode/search/admin`, {params: param.toHttpParam()})
  }

  updateStatusExpire(item: QrCodeDto) {
    return this.http.patch<QrCodeDto>(`${environment.serverUrl}/api/qrcode/update/status/expire` , item)
  }

}
